import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'
import { media } from '~/styles/media'

type Props = SpaceProps &
  ColorProps & { uppercase?: boolean; regular?: boolean }

export const Meta = styled.div<Props>(
  {
    fontWeight: fonts.text.medium,
    fontSize: '0.875rem',
    lineHeight: 1.6,
    color: colors.dark,
    [media.maxSmall]: {
      fontSize: '0.75rem',
    },
  },
  props =>
    props.uppercase && {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
    },
  props =>
    props.regular && {
      fontWeight: fonts.text.regular,
    },
  space,
  color
)
