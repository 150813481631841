import styled from 'styled-components'
import {
  SpaceProps,
  space,
  TextAlignProps,
  textAlign,
  ColorProps,
  color,
} from 'styled-system'

import { fonts } from '~/styles/fonts'
import { colors } from '~/styles/colors'

type Props = SpaceProps &
  TextAlignProps &
  ColorProps & {
    bold?: boolean
  } & {
    ellipsis?: boolean
    numberOfLines?: number
  }

export const Text = styled.p<Props>(
  {
    fontWeight: fonts.text.regular,
    fontSize: '1rem',
    lineHeight: 1.8,
    color: colors.dark,
    wordBreak: 'break-word',

    strong: {
      fontWeight: fonts.text.medium,
    },
  },
  props => props.bold && { fontWeight: fonts.text.medium },
  props =>
    props.ellipsis && {
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: props.numberOfLines ?? 2,
      WebkitBoxOrient: 'vertical',
    },
  space,
  textAlign,
  color
)

Text.defaultProps = {
  bold: false,
}
