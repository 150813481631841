import { SpaceProps } from 'styled-system'

import { Wrapper, Initials, Img, Variants } from './style'

export type AvatarProps = SpaceProps & {
  size: Variants
  user?: {
    firstName?: string | null
    lastName?: string | null
    profilePictureUrl?: string | null
  }
}

export const Avatar = ({ size, user, ...space }: AvatarProps) => {
  if (!user) {
    return <Wrapper variant={size} {...space} />
  }

  const { firstName, lastName, profilePictureUrl } = user

  return (
    <Wrapper variant={size} {...space}>
      {profilePictureUrl ? (
        <Img src={profilePictureUrl} />
      ) : (
        <Initials variant={size}>
          {firstName?.charAt(0)}
          {lastName?.charAt(0)}
        </Initials>
      )}
    </Wrapper>
  )
}
