import styled from 'styled-components'
import { Button } from '~/components/button'
import { Grid } from '~/components/layout'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'
import { media } from '~/styles/media'

export const Wrapper = styled.div({
  background: colors.white,
  borderRadius: 4,
  border: colors.border,
  maxHeight: 'calc(100vh - 100px)',
  flexDirection: 'column',
  overflow: 'auto',

  [media.maxSmall]: {
    width: '100% !important',
    margin: '0',
  },
})

export const Header = styled.header({
  flex: 0,
  borderBottom: colors.border,
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: fonts.text.medium,
  color: colors.dark,
  padding: '1.5rem 0',

  [media.maxSmall]: {
    padding: '1.5rem 2rem',
  },
})

export const Body = styled.div({
  flex: 1,
  padding: '40px 32px 42px',
  '-webkit-overflow-scrolling': 'touch',
  overflow: 'auto',

  [media.maxSmall]: {
    padding: '2.625rem 2rem',
  },
})

export const Footer = styled.footer({
  flexShrink: 0,
  borderTop: colors.border,
  padding: 16,

  [media.maxSmall]: {
    padding: '1rem 2rem',
  },
})

export const GridWrapper = styled(Grid)<{ hiddenCancel?: boolean }>(
  props =>
    props.hiddenCancel && {
      'button:first-of-type': {
        display: 'none',
      },
    },
  props => ({
    columnGap: '1rem',
    gridTemplateColumns: `repeat(${
      props.hiddenCancel ? 1 : 2
    }, minmax(100px, auto))`,
    justifyContent: 'end',

    [media.maxSmall]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      gap: '1rem',
    },
  })
)

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  loading?: boolean
}

export const ButtonWrapper = styled(Button)<ButtonProps>({
  [media.maxSmall]: {
    width: '100%',
  },
})

export const ContentWrapper = styled.div({
  zIndex: 999,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  [media.maxSmall]: {
    width: '90%',
  },
})
