import { createContext, useContext, useEffect } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogStateReturn,
  useDialogState,
} from 'reakit/Dialog'
import { Button } from '~/components/button'
import { Grid } from '~/components/layout'
import { usePrevious } from '~/hooks/use-previous'

import {
  Body,
  ButtonWrapper,
  ContentWrapper,
  Footer,
  GridWrapper,
  Header,
  Wrapper,
} from './style'

type Props = {
  width?: 480 | 560 | 736 | 760 | 884 | 911
  heading?: string
  children: React.ReactNode
}

const ModalContext = createContext<DialogStateReturn | undefined>(undefined)

export const Modal = ({ heading, children, width = 560 }: Props) => {
  return (
    <Wrapper style={{ width }}>
      {heading && <Header>{heading}</Header>}
      {children}
    </Wrapper>
  )
}

export type ModalActionsProps = {
  submitLabel: string
  cancelLabel?: string
  onSubmit?: (hide: () => void) => void
  onCancel?: (hide: () => void) => void
  loading?: boolean
  disabled?: boolean
  hideCancel?: boolean
  buttonType?: 'button' | 'submit'
  danger?: boolean
}

const Actions = ({
  submitLabel,
  cancelLabel = 'Cancel',
  onSubmit,
  onCancel,
  hideCancel,
  buttonType,
  disabled,
  danger,
  loading,
}: ModalActionsProps) => {
  const modal = useContext(ModalContext)

  if (!modal) {
    throw new Error(
      'Modal.Actions must be rendered inside withModal higher-order component'
    )
  }

  const handleSubmit = () => {
    onSubmit?.(modal.hide)
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel?.(modal.hide)
    } else {
      modal.hide()
    }
  }

  return (
    <Footer>
      <GridWrapper hiddenCancel={hideCancel}>
        <ButtonWrapper size="small" onClick={handleCancel} variant="outline">
          {cancelLabel}
        </ButtonWrapper>
        <ButtonWrapper
          type={buttonType}
          size="small"
          loading={loading}
          variant={danger ? 'danger' : undefined}
          onClick={handleSubmit}
          disabled={disabled}
        >
          {submitLabel}
        </ButtonWrapper>
      </GridWrapper>
    </Footer>
  )
}

const ActionsWithoutSubmit = () => {
  const modal = useContext(ModalContext)

  if (!modal) {
    throw new Error(
      'Modal.Actions must be rendered inside withModal higher-order component'
    )
  }

  const handleCancel = () => {
    modal.hide()
  }

  return (
    <Footer>
      <Grid gridColumnGap={16} justifyContent="end">
        <Button size="small" onClick={handleCancel} variant="outline">
          Close
        </Button>
      </Grid>
    </Footer>
  )
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer
Modal.Actions = Actions
Modal.ActionsWithoutSubmit = ActionsWithoutSubmit

type Options = {
  onHide?: () => void
}

export const useModal = ({ onHide }: Options = {}) => {
  const dialog = useDialogState({ animated: true })
  const prevVisible = usePrevious(dialog.visible)

  const { visible } = dialog
  useEffect(() => {
    if (prevVisible && !visible) {
      onHide?.()
    }
  }, [visible])

  return dialog
}

export type ModalProps = {
  hide: () => void
}

export const withModal = <P extends ModalProps = ModalProps>(
  Component: React.ComponentType<P>
): React.ComponentType<P & DialogStateReturn> => {
  const displayName = Component.displayName || Component.name || 'Component'

  const WithModal = (props: P & DialogStateReturn) => {
    return (
      <ModalContext.Provider value={props}>
        {props.visible && (
          <DialogBackdrop
            style={{
              position: 'fixed',
              zIndex: 998,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(0, 0, 0, 0.3)',
            }}
            {...props}
          >
            <Dialog aria-label="Welcome" tabIndex={0} {...props}>
              <ContentWrapper>
                <Component {...(props as P)} />
              </ContentWrapper>
            </Dialog>
          </DialogBackdrop>
        )}
      </ModalContext.Provider>
    )
  }

  WithModal.displayName = `withModal(${displayName})`

  return WithModal
}
