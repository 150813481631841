import { FC, ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import {
  AlignItemsProps,
  BackgroundProps,
  BorderProps,
  FlexProps,
  FlexboxProps,
  GridColumnProps,
  HeightProps,
  JustifyContentProps,
  JustifyItemsProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  GridProps as SystemGridProps,
  WidthProps,
  alignItems,
  background,
  border,
  compose,
  flex,
  flexbox,
  grid,
  gridColumn,
  height,
  justifyContent,
  justifyItems,
  layout,
  position,
  space,
  width,
} from 'styled-system';

type Props = {
  children?: ReactNode
}

export type BoxProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  GridColumnProps &
  BorderProps &
  HeightProps &
  WidthProps &
  BackgroundProps & {
    className?: string
    style?: CSSProperties
  } & Props

export const Box = styled('div')<BoxProps>(
  compose(
    space,
    layout,
    flexbox,
    position,
    gridColumn,
    border,
    height,
    width,
    background
  )
)

export const Flex: FC<BoxProps> = styled(Box)({
  display: 'flex',
})

export type GridProps = SystemGridProps &
  SpaceProps &
  LayoutProps &
  AlignItemsProps &
  GridColumnProps &
  FlexProps &
  JustifyContentProps &
  JustifyItemsProps

export const Grid = styled('div')<GridProps>(
  {
    display: 'grid',
  },
  compose(
    space,
    grid,
    alignItems,
    layout,
    gridColumn,
    flex,
    justifyContent,
    justifyItems
  )
)

export const Gap = styled('div')<{ gap: number }>(props => ({
  '& > :not(:last-child)': {
    marginRight: props.gap,
    marginBottom: props.gap,
  },
}))
