import styled from 'styled-components'
import type { CSSObject } from 'styled-components'
import { variant } from 'styled-system'

import { fonts } from '~/styles/fonts'
import { colors } from '~/styles/colors'

import { Box } from '../layout'

export type Variants = 40 | 56 | 96 | 120

export const Wrapper = styled(Box)<{ variant?: Variants }>(
  {
    position: 'relative',
    borderRadius: '100%',
    overflow: 'hidden',
    border: colors.border,
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    background: colors.white,
  },
  variant<CSSObject>({
    variants: {
      40: {
        minWidth: 40,
        minHeight: 40,
        width: 40,
        height: 40,
      },
      56: {
        minWidth: 56,
        minHeight: 56,
        width: 56,
        height: 56,
      },

      96: {
        minWidth: 96,
        minHeight: 96,
        width: 96,
        height: 96,
      },

      120: {
        minWidth: 120,
        minHeight: 120,
        width: 120,
        height: 120,
      },
    },
  })
)

export const Initials = styled.p<{ variant?: Variants }>(
  {
    fontWeight: fonts.text.medium,
    textAlign: 'center',
    color: colors.slateGray,
    textTransform: 'uppercase',
  },
  variant<CSSObject>({
    variants: {
      40: {
        fontSize: '0.875rem',
        lineHeight: 2.8,
      },
      56: {
        fontSize: '1.25rem',
        lineHeight: 2.6,
      },
      96: {
        fontSize: '1.5rem',
        lineHeight: 4,
      },
      120: {
        fontSize: '1.875rem',
        lineHeight: 3.8,
      },
    },
  })
)

export const Img = styled.img({
  width: '100%',
  height: '100%',
})
